/* Common Imports */

import React from "react";
import { styled } from "@mui/system";

/* Redux Imports */

import StateInterface from "../../redux-magic/state-interface";
import { SearchFilterId, SearchFilterOptionRange } from "../../redux-magic/sub-interfaces/search-types";
import { updateSearchFilterAppliedThunk, updateSearchFilterSelectedOptionsThunk } from "../../redux-magic/thunks";

/* Component Imports */

import {
	// Accordion,
	// AccordionDetails,
	// AccordionSummary,
	Divider,
	// Slide,
	TextField,
	Typography,
	Slider,
} from "@mui/material";

import { useRouter } from "next/router";

/* Icon Imports */

import { Brightness1, PaymentsOutlined } from "@mui/icons-material";

/* Library Function Imports */

// import CurrencyToNumberPipe from "../../lib/CurrencyToNumberPipe";
// import NumberToCurrencyPipe from "../../lib/NumberToCurrencyPipe";
import PreparePriceString from "../../lib/PreparePriceString";

/* Styled Components */

// const CustomAccordion = styled(Accordion)(({ theme }) => ({
// 	borderRadius: "16px",
// 	boxShadow: "none",
// 	"&:: before": {
// 		display: "none",
// 	},
// }));

// const CustomAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
// 	padding: "0.5rem 0.75rem 0.5rem 0.75rem",
// 	margin: "0rem",
// 	fontSize: "1rem",
// 	[theme.breakpoints.up("sm")]: {
// 		fontSize: "1rem",
// 	},
// 	[theme.breakpoints.up("md1190")]: {
// 		fontSize: "1.125rem",
// 		padding: "1rem 1rem 1rem 1rem",
// 	},
// 	"& .MuiAccordionSummary-content": {
// 		display: "flex",
// 		flexDirection: "row",
// 		justifyContent: "flex-start",
// 		alignItems: "center",
// 		margin: "0rem",
// 		[theme.breakpoints.up("md1190")]: {
// 			margin: "0.75rem 0rem",
// 		},
// 	},
// }));

const Container = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "column",
	gap: "0.5rem",
	padding: "1rem",
	borderRadius: "16px",
	background: theme.palette.background.paper,
}));

const HeaderContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	width: "100%",
	color: theme.palette.text.primary,
	gap: "0.5rem",
}));

const ContentContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "center",
	alignItems: "center",
	flexWrap: "wrap",
	margin: "0rem",
	gap: "0.5rem",
	[theme.breakpoints.up("md1190")]: {
		// padding: "0rem 1rem 1rem 1rem",
		gap: "1rem",
	},
}));

const CustomCategoryIcon = styled(PaymentsOutlined)(({ theme }) => ({
	margin: "0rem 0.5rem 0rem 0rem",
	color: theme.palette.primary.main,
	fontSize: "1.25rem",
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1.5rem",
	},
}));

const Heading = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	[theme.breakpoints.up("sm")]: {
		fontSize: "0.875rem",
	},
	[theme.breakpoints.up("md1190")]: {
		fontSize: "1rem",
	},
}));

// const CustomArrowIcon = styled(KeyboardArrowDownOutlinedIcon)(({ theme }) => ({
// 	width: "1.5rem",
// 	height: "1.5rem",
// 	borderRadius: "4px",
// 	color: "#6e3037",
// 	backgroundColor: "#ffe9d9",
// }));

const CustomSelectedIcon = styled(Brightness1)(({ theme }) => ({
	color: theme.palette.primary.main,
	width: "8px",
	marginTop: "-0.25rem",
	marginLeft: "0.25rem",
}));

// const CustomAccordionDetails = styled(AccordionDetails)(({ theme }) => ({
// 	display: "flex",
// 	flexDirection: "row",
// 	justifyContent: "center",
// 	alignItems: "center",
// 	flexWrap: "wrap",
// 	margin: "0rem",
// 	gap: "1.5rem",
// 	padding: "0rem 0.75rem 1rem 0.75rem",
// 	[theme.breakpoints.up("md1190")]: {
// 		padding: "0rem 1rem 1rem 1rem",
// 		gap: "1rem",
// 	},
// }));

const TextFieldsContainer = styled("div")(({ theme }) => ({
	display: "flex",
	flexDirection: "row",
	justifyContent: "space-between",
	alignItems: "center",
	flexWrap: "nowrap",
	width: "100%",
	margin: "0rem",
	gap: "0.5rem",
	padding: "0rem 0rem 0rem 0rem",
}));

const CustomSlider = styled(Slider)(({ theme }) => ({
	width: "90%",
	"& .MuiSlider-track": {
		border: "none",
		height: 4,
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "2px solid currentColor",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&::before": {
			display: "none",
		},
	},
	"& .MuiSlider-valueLabel": {
		lineHeight: 1.2,
		fontSize: 12,
		background: "unset",
		padding: 0,
		width: "4rem",
		height: "2rem",
		borderRadius: "0.5rem",
		backgroundColor: "#ffe9d9",
		color: "#5f2b01",
	},
}));

const Text = styled(TextField)(({ theme }) => ({
	"& .MuiOutlinedInput-root": {
		borderRadius: "0.5rem",
		fontSize: "0.825rem",
		fontWeight: "400",
		// lineHeight: "1rem",
		// height: "3.35rem",
	},
}));

const SubText = styled(Typography)(({ theme }) => ({
	fontSize: "0.875rem",
	fontWeight: "500",
	lineHeight: "1rem",
	padding: "0rem 0rem 1.5rem 0rem",
	color: theme.palette.text.secondary,
}));

export const _f_budget = ({
	search_id,
	dispatch,
	budget,
	transaction_type,
}: {
	search_id: SearchFilterId;
	dispatch: Function;
	budget: StateInterface["search_filters_state"]["budget"];
	transaction_type: StateInterface["search_filters_state"]["transaction_type"];
}) => {
	const router = useRouter();

	const [source, setSource] = React.useState<string>("");

	const [minBudget, setMinBudget] = React.useState<number>(0);
	const [maxBudget, setMaxBudget] = React.useState<number>(0);

	// const [slide, setSlide] = React.useState<boolean>(false);
	const [errorMinPrice, setErrorMinPrice] = React.useState<boolean>(false);
	const [errorMaxPrice, setErrorMaxPrice] = React.useState<boolean>(false);

	const [minBudgetText, setMinBudgetText] = React.useState<number>(0);
	const [maxBudgetText, setMaxBudgetText] = React.useState<number>(0);

	React.useEffect(() => {
		setMinBudget(0);
		setMaxBudget(0);
		setMinBudgetText(0);
		setMaxBudgetText(0);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [transaction_type.selected_options[0]?.id]);

	React.useEffect(() => {
		if (budget.is_applied && source !== "main_filter") {
			setMinBudget(
				budget.is_applied &&
					typeof budget.selected_options[0]?.value === "object" &&
					"min" in budget.selected_options[0]?.value
					? decalculateValue(budget.selected_options[0].value.min)
					: 0,
			);
			setMaxBudget(
				budget.is_applied &&
					typeof budget.selected_options[0]?.value === "object" &&
					"max" in budget.selected_options[0]?.value
					? decalculateValue(budget.selected_options[0].value.max)
					: 0,
			);
			setMinBudgetText(
				budget.is_applied &&
					typeof budget.selected_options[0]?.value === "object" &&
					"min" in budget.selected_options[0]?.value
					? budget.selected_options[0].value.min
					: 0,
			);
			setMaxBudgetText(
				budget.is_applied &&
					typeof budget.selected_options[0]?.value === "object" &&
					"max" in budget.selected_options[0]?.value
					? budget.selected_options[0].value.max
					: 0,
			);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [budget]);

	const minBud = (budget.selected_options[0] as SearchFilterOptionRange)?.value?.min;

	const maxBud = (budget.selected_options[0] as SearchFilterOptionRange)?.value?.max;

	/* Formatting slider value label */

	function valueLabelFormat(value: number) {
		return `${PreparePriceString(value)}`;
	}

	/* Calculating slider scaling value */

	function calculateValue(value: number) {
		if (transaction_type.selected_options[0]?.id === "rent") {
			//scale double after 1 L, 1 L should be in middle

			if (value < 325000) {
				return parseInt((value * 0.4).toFixed(0));
			} else if (value >= 325000) {
				return Math.floor(((value - 250000) * 5) / 2.5);
			} else {
				return parseInt(value.toFixed(0));
			}
		} else {
			//scale double after 2 cr, 2 cr should be in middle

			if (value < 50000000) {
				return parseInt((value * 0.4).toFixed(0));
			} else if (value >= 50000000) {
				return Math.floor(((value - 30000000) * 10) / 7);
			} else {
				return parseInt(value.toFixed(0));
			}
		}
	}

	/* De-calculating slider scaling value */

	function decalculateValue(value: number) {
		if (transaction_type.selected_options[0]?.id === "rent") {
			if (value < 130000) {
				return parseInt((value / 0.4).toFixed(0));
			} else if (value >= 150000) {
				return Math.floor((value * 2.5) / 5 + 250000);
			} else if (value >= 130000) {
				return Math.floor((value * 2.55) / 5 + 250000);
			} else {
				return parseInt(value.toFixed(0));
			}
		} else {
			if (value < 20000000) {
				return parseInt((value / 0.4).toFixed(0));
			} else if (value === 20000000) {
				return value + 29999999;
			} else if (value > 20000000) {
				return Math.floor((value * 7) / 10 + 30000000);
			} else {
				return parseInt(value.toFixed(0));
			}
		}
	}

	/* Slider markers (Sale) */

	const sale_markers = [
		{
			value: 0,
			scaledValue: 0,
			label: "0",
		},
		{
			value: 50000000,
			label: "2Cr",
		},
		{
			value: 100000000,
			label: "10Cr+",
		},
	];

	/* Slider markers (Rent) */

	const rent_markers = [
		{
			value: 0,
			scaledValue: 0,
			label: "0",
		},
		{
			value: 250000,
			label: "1L",
		},
		{
			value: 500000,
			label: "5L+",
		},
	];

	const handleUpdateMinBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSource("main_filter");

		const value = e.target.value;
		const numberValue = parseInt(value);

		if (value === "") {
			setMinBudgetText(0);
			setMinBudget(0);
		} else {
			// const numberValue = CurrencyToNumberPipe(value);
			if (!isNaN(numberValue)) {
				setMinBudgetText(numberValue);
				setMinBudget(decalculateValue(numberValue));
			} else {
				setMinBudgetText(0);
				setMinBudget(0);
			}
		}

		if (maxBudgetText !== 0 && numberValue > maxBudgetText) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		// Dispatch updated budget range
		// handleUpdateMinBudgetDispatch(numberValue);
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "budget",
						title: "Budget",
						value: {
							min: Number.isNaN(numberValue) ? 0 : numberValue,
							max: maxBudgetText,
							unlock_max: maxBudgetText === 0 || Number.isNaN(maxBudgetText) ? true : false,
						},
					},
				],
			}),
		);

		setSource("");
	};

	// const handleUpdateMinBudgetDispatch = debounce((value: number) => {
	// 	dispatch(
	// 		updateSearchFilterSelectedOptionsThunk({
	// 			searchFilterId: search_id,
	// 			newOptions: [
	// 				{
	// 					id: "budget",
	// 					title: "Budget",
	// 					value: {
	// 						min: value,
	// 						max: maxBudget,
	// 						unlock_max: maxBudgetText === 0 ? true : false,
	// 					},
	// 				},
	// 			],
	// 		}),
	// 	);
	// }, 300);

	const handleUpdateMaxBudget = (e: React.ChangeEvent<HTMLInputElement>) => {
		setSource("main_filter");

		const value = e.target.value;
		const numberValue = parseInt(value);
		if (value === "") {
			setMaxBudgetText(0);
			setMaxBudget(0);
		} else {
			// const numberValue = CurrencyToNumberPipe(value);
			if (!isNaN(numberValue)) {
				setMaxBudgetText(numberValue);
				setMaxBudget(decalculateValue(numberValue));
			} else {
				setMaxBudgetText(0);
				setMaxBudget(transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000);
			}
		}
		if (minBudgetText !== 0 && numberValue < minBudgetText) {
			setErrorMinPrice(true);
			setErrorMaxPrice(true);
		} else {
			setErrorMinPrice(false);
			setErrorMaxPrice(false);
		}

		// Dispatch updated budget range
		dispatch(
			updateSearchFilterSelectedOptionsThunk({
				searchFilterId: search_id,
				newOptions: [
					{
						id: "budget",
						title: "Budget",
						value: {
							min: minBudgetText,
							max: Number.isNaN(numberValue) ? 0 : numberValue,
							unlock_max: numberValue === 0 || Number.isNaN(numberValue) ? true : false,
						},
					},
				],
			}),
		);

		dispatch(
			updateSearchFilterAppliedThunk({
				searchFilterId: search_id,
				isApplied: true,
			}),
		);

		setSource("");
	};

	return (
		<Container>
			<HeaderContainer>
				<CustomCategoryIcon
					sx={{
						margin: router.locale === "ar-AE" ? "0rem 0rem 0rem 0.5rem" : "0rem 0.5rem 0rem 0rem",
					}}
				/>
				<Heading variant="body2">
					{router.locale === "ar-AE" ? "السعر" : router.locale === "kn-IN" ? "ಬಜೆಟ್" : "Budget"}
				</Heading>
				{(minBud || maxBud) > 0 ? <CustomSelectedIcon /> : null}
			</HeaderContainer>

			<Divider
				sx={{
					borderBottom: "1px solid var(--Other-New-Divider, rgba(255, 255, 255, 0.12))",
				}}
			/>

			{/* <Slide
				direction="up"
				in={slide}
				mountOnEnter
				unmountOnExit
			> */}
			<ContentContainer>
				<CustomSlider
					valueLabelDisplay="auto"
					marks={transaction_type.selected_options[0]?.id === "rent" ? rent_markers : sale_markers}
					min={0}
					max={transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000}
					scale={calculateValue}
					getAriaValueText={valueLabelFormat}
					valueLabelFormat={valueLabelFormat}
					value={[
						minBudget,
						maxBudget === 0 ? (transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000) : maxBudget,
					]}
					onChange={(e, value: number | number[]) => {
						if (Array.isArray(value)) {
							setMinBudget(value[0]);
							setMaxBudget(value[1]);
							setMinBudgetText(calculateValue(value[0]));
							setMaxBudgetText(calculateValue(value[1]));
						}
					}}
					onChangeCommitted={(e, value: number | number[]) => {
						setSource("main_filter");

						if (Array.isArray(value)) {
							setMinBudget(value[0]);
							setMaxBudget(value[1]);
							setMinBudgetText(calculateValue(value[0]));
							setMaxBudgetText(calculateValue(value[1]));

							dispatch(
								updateSearchFilterSelectedOptionsThunk({
									searchFilterId: search_id,
									newOptions: [
										{
											id: "budget",
											title: "Budget",
											value: {
												min: calculateValue(value[0]),
												max:
													calculateValue(value[1]) ===
													(transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000)
														? 0
														: calculateValue(value[1]),
												unlock_max:
													value[1] === 0 ||
													value[1] === (transaction_type.selected_options[0]?.id === "rent" ? 500000 : 100000000)
														? true
														: false,
											},
										},
									],
								}),
							);

							dispatch(
								updateSearchFilterAppliedThunk({
									searchFilterId: search_id,
									isApplied: true,
								}),
							);

							setSource("");
						}
					}}
				/>

				<TextFieldsContainer>
					<Text
						value={minBudgetText === 0 ? "" : minBudgetText}
						// placeholder={
						// 	router.locale === "ar-AE" ? "الحد الأدنى للسعر" : router.locale === "kn-IN" ? "ಕಡಿಮೆ ಬಜೆಟ್" : "Minimum"
						// }
						variant="outlined"
						size="small"
						sx={{ width: "100%" }}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMinBudget(e);
						}}
						// slotProps={{
						// 	input: {
						// 		endAdornment: (
						// 			<InputAdornment position="end">
						// 				<Typography
						// 					variant="body2"
						// 					color="textSecondary"
						// 					fontWeight={400}
						// 				>
						// 					{"INR"}
						// 				</Typography>
						// 			</InputAdornment>
						// 		),
						// 	},
						// }}
						error={errorMinPrice}
						helperText={
							errorMinPrice
								? "Value is more than max budget"
								: minBudgetText === 0
									? "Minimum"
									: PreparePriceString(minBudgetText)
						}
						autoComplete="off"
					/>

					<SubText>-</SubText>

					<Text
						value={maxBudgetText === 0 ? "" : maxBudgetText}
						// placeholder={
						// 	router.locale === "ar-AE" ? "الحد الأقصى للسعر" : router.locale === "kn-IN" ? "ಗರಿಷ್ಠ ಬಜೆಟ್" : "Maximum"
						// }
						variant="outlined"
						size="small"
						sx={{ width: "100%" }}
						onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
							handleUpdateMaxBudget(e);
						}}
						error={errorMaxPrice}
						helperText={
							errorMinPrice
								? "Value is less than min budget"
								: maxBudgetText === 0
									? "Maximum"
									: PreparePriceString(maxBudgetText)
						}
						autoComplete="off"
					/>
				</TextFieldsContainer>
			</ContentContainer>
			{/* </Slide> */}
		</Container>
	);
};
